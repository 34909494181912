 var save_id = 0;
 var cpt_save = 0;
 var from_range = false; //for wishlist

 function addEvent(elem, event, name_function) {
    if (document.all) elem.attachEvent("on" + event, name_function);
     else elem.addEventListener(event, name_function, false);
 }

 function delEvent(elem, event, name_function) {
     if (document.all) elem.detachEvent("on" + event, name_function);
     else elem.removeEventListener(event, name_function, false);
 }

// // empeche les evenements de remonter dans l'arbre

 function doNothing(e) {
     if (document.all) {
         if (!e) var e = window.event;
         e.cancelBubble = true;
     } else {
         e.stopPropagation();
     }
     return false;
 }
 // popup guide des tailles
 function showsizeguide(){
     var elname = 'sizeguidebox';
     var actualposition = document.documentElement.scrollTop;
     var modbox = document.getElementById(elname);
     var shade = document.getElementById('shade');
     modbox.style.position = "absolute";
     shade.style.display="block";
     addEvent(shade,'click',closesizeguide);

     addEvent(document.getElementById('menu_sizeguide'), 'click', doNothing);

     modbox.style.display="block";

     window.scrollTo(0,actualposition);
 }

 function closesizeguide() {
     var elname = 'sizeguidebox';
     var shade = document.getElementById('shade');
     var modbox = document.getElementById(elname);
     modbox.style.display="";

     delEvent(shade,'click',closesizeguide);
     delEvent(modbox,'click',closesizeguide);
     delEvent(document.getElementById('menu_sizeguide'), 'click', doNothing);
     closemodal();
 }

 function showmodal(produit_id) {
     var actualposition = document.documentElement.scrollTop;
     var modbox = document.getElementById("modbox");
     var cmodbox = document.getElementById("cmodbox");
     var panmodbox = document.getElementById("panmodbox");
     var shade = document.getElementById("shade");
     document.body.style.overflow = "hidden";

     cmodbox.innerHTML = "";
     panmodbox.innerHTML = "";

     modbox.style.marginTop = actualposition - 260 + "px";
     shade.style.display = "block";
     modbox.style.display = "block";
     document.getElementsByTagName("html")[0].style.overflow = "hidden";

     window.scrollTo(0, actualposition);
 }

 var addToCartTmpl;

 function buildItemAddedModbox(id_produit, is_not_produit) {
     var form = document.getElementById("prod_info_" + id_produit);
     var closing_method =
         is_not_produit == "true"
             ? "window.location.reload()"
             : "closeMultiShad('modbox')";

     if (document.getElementById("nbr_visu_0")) {
         var itm_vis = $("img", "#nbr_visu_0")
             .attr("src")
             .replace("/mini/", "/large/");
     }

     var itm_name = $("#itm_name", form)
         .get(0)
         .textContent.trim();

     if ($("#itm_feat", form).length > 0) {
         var itm_feat = $("#itm_feat", form)
             .get(0)
             .textContent.trim();
     }

     var itm_price = $(".pricetag:first", form).html();

     if ($(".pricetag:first + .pricetag", form).length) {
         var itm_fullprice = $(".pricetag:first + .pricetag", form).html();
     }

     var itm_price = $(".price_tag:first", form).html();

     if ($(".price_tag:first + .price_tag", form).length) {
         var itm_fullprice = $(".price_tag:first + .price_tag", form).html();
     }

     if (form) {
         var itm_length = parseFloat(form.elements.namedItem("qteProd").value);
         var itm_color = form.elements.namedItem("couleurProd").value;
         var itm_size = form.elements.namedItem("tailleProd").value;

         if (form.getValue("produit_lot_nb", parseFloat) > 1 || itm_length > 1) {
             itm_length += " " + translate("items");
         } else {
             itm_length += " " + translate("item");
         }

         if (itm_length == 1) {
             itm_length += " " + translate("added_to_cart_sn");
         } else if (form.getValue("produit_lot_nb") == "1") {
             itm_length += " " + translate("added_to_cart_pl");
         }

         itm_color = $("input[name=itm_color]", form)
             .filter("[value=" + itm_color + "]")
             .next("label")
             .attr("title")
             .trim();
         itm_size = $("input[name=itm_size]", form)
             .filter("[value=" + itm_size + "]")
             .next("label")
             .attr("title")
             .trim();
     }

     $("#shade").after($("#modbox"));

     document.getElementById("modbox").innerHTML = Mustache.render(
         addToCartTmpl,
         {
             closing_method: closing_method,
             itm_vis: itm_vis,
             itm_name: itm_name,
             itm_feat: itm_feat,
             itm_price: itm_price,
             itm_fullprice: itm_fullprice,
             itm_length: itm_length,
             itm_color: itm_color,
             itm_size: itm_size,
         }
     );
     // Si c'est la premiere fois on crée l'image et ensuite on l'insere a la suite
     // C'est pour éviter que le serveur appel une image qui n'existe pas
     if ($("#modbox_visu").length > 0) {
         var span = $("#modbox_visu");
         // Creation de l'image
         var img = $("<img>", {
             src: span.data("src"),
             alt: span.data("alt"),
         });
         $("#modbox_visu").after(img);
         $("#modbox_visu").remove();
     }
 }

 function newshowmodal(products, type, root, id_produit, is_not_produit)
 {
     topCartAppear();
     window.setTimeout(function () {

         topCartDesappear();
     }, 5000);
 }

 function closemodal() {
     var modbox = document.getElementById("modbox");
     var errbox = document.getElementById("errbox");
     var cmodbox = document.getElementById("cmodbox");
     var shade = document.getElementById("shade");

     if (cmodbox) {
         cmodbox.innerHTML = "";
     }

     if (modbox) {
         modbox.style.marginLeft = "";
         modbox.style.display = "none";
     }

     if (shade) {
         shade.style.display = "none";
     }

     if (errbox) {
         errbox.style.display = "none";
     }

     document.body.style.overflow = "";
     document.getElementsByTagName("html")[0].style.overflow = "";

     if (
         navigator.userAgent.indexOf("MSIE") != -1 &&
         navigator.userAgent.indexOf("6.") != -1
     ) {
         var svn = document.getElementsByTagName("SELECT");
         for (a = 0; a < svn.length; a++) {
             svn[a].style.visibility = "visible";
         }
     }
 }

 function waitingAddToBasket(evt, form_elm, from, itm_rr_id) {

     var id = '';
     var produit_id = form_elm.getValue('produit_id');
     var suff = form_elm.getValue('suff');
     var panier_id = form_elm.getValue('panier_id');
     var titreObjet = form_elm.getValue('titreObjet');
     var idTaille = form_elm.getValue('idTaille');
     var lastTaille = form_elm.getValue('lastTaille');
     var idCouleur = form_elm.getValue('idCouleur');
     var lastCouleur = form_elm.getValue('lastCouleur');
     var toEval = form_elm.getValue('toEval');
     var page_name = form_elm.getValue('page_name');
     var path_web = form_elm.getValue('path_web');
     var enable_omniture = form_elm.getValue('enable_omniture');
     var is_not_produit = form_elm.getValue('is_not_produit');
     var is_lightbox_specifique = form_elm.getValue('is_lightbox_specifique');
     var is_achat_express = $('#is_achat_express_v2').val() == 1;
     var loader;

     if(!is_achat_express)
         evt.preventDefault();

     if (from === undefined) {

        from = '';
     }

     if (is_not_produit == true) {

         id += '_' + produit_id;
     }

     if (this.nextElementSibling && this.nextElementSibling.classList.contains('loader')) {

         loader = $(this.nextElementSibling);

         loader.addClass("loading");

     } else {

         $("#bloc_btn_active" + id).attr('class', 'cache');
         $("#bloc_btn_loader" + id).attr('class', 'f_right');
     }

     if (is_lightbox_specifique == 'true') {

         add_produit_specifique(produit_id, idCouleur, idTaille);
     } else {

         window.setTimeout(addToBasket, 1000, produit_id, suff, panier_id, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur, toEval, page_name, path_web, enable_omniture, is_not_produit, from, loader, is_achat_express, itm_rr_id);
     }
 }

 function addToBasket(produit_id, suff, panier_id, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur, toEval, page_name, path_web, enable_omniture, is_not_produit, from, loader, is_achat_express, itm_rr_id) {

     var id = '';

     prix_id = 'prixU';

     if (from == 'basket' || is_not_produit == true || is_achat_express) {

         id += '_' + produit_id + itm_rr_id;
         prix_id = 'prixU' + id;

         if (is_not_produit == true) {

             var code_color = $('#couleurUnique' + id).text();
             var nom_color = $('#couleurUnique' + id).text();
         }
     }

     var array_panier = newUpdatePanier(produit_id, suff, panier_id, document.getElementById(prix_id).value, path_web, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur);

     if (array_panier != false) {

         if (from == 'basket') {

             location.reload();

             return false;
         }

         var type = '';

         if (array_panier[1] == 'false' || array_panier.length == 0) {

             type = 'err';
         }

         omnitureEvent = '';

         if (array_panier[1] == 1) {

             omnitureEvent = 'scOpen,scAdd'; // le premier produit ajout� au panier
         } else {

             omnitureEvent = 'scAdd'; // il y a d�j� un produit au panier
         }

         if (loader) {

            loader.parent().removeClass('loading');
            loader.removeClass('loading');


         } else {

             $("#bloc_btn_active" + id).attr('class', '');
             $("#bloc_btn_loader" + id).attr('class', 'f_right cache');
         }

         if (array_panier[1] > 0 && enable_omniture == true) {

             void(s.t());
             s.pageName = "'" + page_name + "'";
             s.products = ";" + produit_id;
             s.events = omnitureEvent;
             void(s.t());
         }

         // DATALAYER V2
         if (array_panier.length >= 0 && array_panier[5] !== undefined) {
             pushIntoDatalayer(array_panier[5]);
         }

         // Mise à jour du panier top
         var response_basket = ajax_file(path_web + 'ajax_show_basket.php');

         $("#cart_top").html(response_basket);

         var count_bask = $('#cart_top .cart').attr('data-cart');

         $("#lightbox_achat_express, .lightbox_achat_express, #shad").hide();

        if($('.page_rayon.rayon_lookbook').length) {
            $('body').css('overflow','');
        }

         newshowmodal(produit_id, is_not_produit);
     } else {

         if (loader) {

             loader.parent().removeClass('loading');
         } else {

             $("#bloc_btn_active" + id).attr('class', '');
             $("#bloc_btn_loader" + id).attr('class', 'f_right cache');
         }
     }

     eval(toEval);

     if (array_panier != false) {

         if (navigator.userAgent.indexOf('MSIE') != -1 && navigator.userAgent.indexOf('6.') != -1) {

             var svn = document.getElementsByTagName("SELECT");

             for (a = 0; a < svn.length; a++) {

                 svn[a].style.visibility = "hidden";
             }
         }
     }

     return false;
 }

 function displayZoom(
     picture,
     product_ref,
     path_web,
     picture_medium,
     picture_mini,
     photo_path,
     photo_list
 ) {
     var so = new SWFObject(
         path_web + "zoom.swf",
         "zoom",
         "405",
         "610",
         "8",
         "#FFFFFF"
     );
     so.addParam("wmode", "transparent");
     so.addParam("allowScriptAccess", "always");
     so.addVariable("produit_id", product_ref);
     so.addVariable("url", path_web);

     so.addVariable("photo_path_medium", picture_medium);
     so.addVariable("photo_path_mini", picture_mini);

     so.addVariable("photo_path", photo_path);

     so.addVariable("photo_list", photo_list);
     so.addVariable("img_defaut", picture);
     so.write("flashVisuel");
 }

 function zoom(
     picture,
     product_ref,
     path_web,
     picture_medium,
     picture_mini,
     photo_path,
     photo_list
 ) {
     displayZoom(
         picture,
         product_ref,
         path_web,
         picture_medium,
         picture_mini,
         photo_path,
         photo_list
     );
 }

 function updateZoomedImg(src) {
     $(".main_vis img", this).attr("src", src);
 }


 function zoomImg(slide) {
     $('#zoom_box_wrapper').css('opacity', 0);
     window.setTimeout(function () {

        if($("#thumbs_scroller").length > 0) {
            var zoomThumbs = new Swiper('#thumbs_scroller', {
                slidesPerView: 'auto',
                initialSlide: slide - 1,
                spaceBetween: 14,
                direction: 'vertical',
                mousewheel: true,
                watchSlidesVisibility: true,
                watchSlidesProgress: true,
            });
        }
        
        if($("#zoom_box_wrapper .swiper-slide").length > 0) {
            var main_productZoom = new Swiper('#zoom_box_wrapper', {
                effect: 'fade',
                slidesPerView: 1,
                initialSlide: slide - 1,
                mousewheel: false,
                navigation: {
                    nextEl: '.thumbsNext',
                    prevEl: '.thumbsPrev',
                },
                thumbs: {
                    swiper: zoomThumbs
                },
                preloadImages: true,
                lazy: true,
                loadPrevNext: true,
                loadOnTransitionStart: true

            });
        }
     }, 50);

     window.setTimeout(function () {
         $('#zoom_box_wrapper').animate({opacity: 1}, 650);
     }, 350);

     openMultiShad('zoomBox');
 }


 function updateSrc(path_web, dest) {
     var tmp_string_large = document.getElementById("img_large").src;
     tmp_string = tmp_string_large.replace(/medium/, "large");

     document
         .getElementById("img_large")
         .setAttribute(
             "onclick",
             "zoomImg('" + tmp_string + "','" + path_web + "')"
         );
     document.getElementById("loupe").href =
         "javascript:zoomImg('" + tmp_string + "','" + path_web + "');";
     $(".zoomLens").css({backgroundImage: 'url("' + tmp_string_large + '")'});

     if (dest == "vue") {
         var i = 0;
         var trouve = false;
         while (i < tab_js.length && !trouve) {
             trouve =
                 tab_js[i][0].substring(tab_js[i][0].lastIndexOf("/") + 1) ==
                 tmp_string.substring(tmp_string.lastIndexOf("/") + 1);
             i++;
         }

         cpt_img = i - 1;
     }
 }

 function prodGetProductDisplay(id, path_web, type) {
     if (typeof type == "undefined") {
         var response = ajax_file(
             path_web + "ajax_get_infos_send_friend.php?product_id=" + id
         );
         document.getElementById("sendfriend_prodinfos").innerHTML = response;
     } else {
         if (type == "look") {
             $("#titre_popup").html(
                 ajax_file(
                     path_web + "ajax_sprintf.php?arg1=sendfriend_title_look "
                 )
             );
             $("#friend_form").show();
             $("#btn_popup_panier").hide();

             $("#bloc_btn_look").show();
             $("#bloc_btn_loader_look").hide();
         } else if (type == "look_basket") {
             $("#titre_popup").html("Vous venez d'ajouter le look suivant");
             $("#friend_form").hide();
             $("#btn_popup_panier").show();

             $("#bloc_btn_look").show();
             $("#bloc_btn_loader_look").hide();
         } else {
             $("#titre_popup").html("Vous venez d'ajouter le produit suivant");
             $("#friend_form").hide();
             $("#btn_popup_panier").show();
         }
     }

     if (
         navigator.userAgent.indexOf("MSIE") != -1 &&
         navigator.userAgent.indexOf("6.") != -1
     ) {
         var svn = document.getElementsByTagName("SELECT");
         for (a = 0; a < svn.length; a++) {
             svn[a].style.visibility = "hidden";
         }
     }
 }

 function show_lightbox(lightbox_contact) {
     $("#shad")
         .fadeTo("slow", 0.8)
         .queue(function() {
             $("#" + lightbox_contact).slideDown("slow", function() {
                 $("#visu_popup_ajout_panier", this).recalImg();
             });

             $(this).dequeue();
         })
         .click(function() {
             close_lightbox(lightbox_contact);
         });

     $("html, body").animate({scrollTop: 0}, $(document).scrollTop() * 0.8);
 }

 function close_lightbox(lightbox_contact) {
     $("#" + lightbox_contact)
         .slideUp("slow")
         .queue(function() {
             $("[id^=shad]").each(function() {
                 if ($(this).is(":visible")) {
                     $(this).fadeTo("slow", 0, function() {
                         $(this).css("display", "none");
                     });
                 }
             });

             $(this).dequeue();
         });
 }

function changeColorProduct(id_color, from, prod_id) {
     var txt_add = "";
     if (from == "produit") {
         txt_add = "_prod";
         id_select = id_color + "_prod";
         var tab = tab_color_border;
     } else if (from == "assoc") {
         txt_add = "_assoc_" + prod_id;
         window["id_select_assoc_" + prod_id] = id_color + "_assoc_" + prod_id;
         var tab = eval("tab_color_border_assoc_" + prod_id);
         prod_id = "_" + prod_id;
     } else if (from == "gamme") {
         txt_add = "_gamme_" + prod_id;
         window["id_select_gamme_" + prod_id] = id_color + "_gamme_" + prod_id;
         var tab = eval("tab_color_border_gamme_" + prod_id);
         prod_id = "_" + prod_id;
     }

     document.getElementById("couleurProd" + prod_id).value = id_color;

     var cnt_tab = tab.length;

     for (var i = 0; i < cnt_tab; i++) {
         if (tab[i] == id_color) {
             if (document.getElementById(tab[i] + txt_add))
                 document.getElementById(tab[i] + txt_add).style.border =
                     "1px solid #FD6D0C";
         } else {
             if (document.getElementById(tab[i] + txt_add))
                 document.getElementById(tab[i] + txt_add).style.border =
                     "1px solid #999";
         }
     }
}


/**
    * Dans la fiche produit affiche un champs email pour que l'ont previenne que le produit est disponible
*/
function lightboxAlertStock() {

     var $taille_id = $("#tailleProd").val(),
         $couleur_id = $("#couleurProd").val();

     if ($taille_id == '') {
         if (!$('#sizeerror').is(':visible')) {
             $('#sizeerror').slideDown();
         }
         return;
     } else if ($couleur_id == '') {
         alert(translate('js_error_couleur'));
         return;
     }

     var isFormVisible = $('.bloc_add_alert_form').is(':visible');
     var isMessageVisible = $('.bloc_add_alert_confirmation').is(':visible');
     var isErrorVisible = $('.bloc_add_alert_erreur').is(':visible');

     if (isFormVisible || isMessageVisible || isErrorVisible) {

         if (isFormVisible) {

             $('.bloc_add_alert_form').slideUp('fast');
         }

         if (isMessageVisible) {

             $('.bloc_add_alert_confirmation').slideUp('fast');
         }

         if (isErrorVisible) {

             $('.bloc_add_alert_erreur').slideUp('fast');
         }
     } else {
        $('.bloc_add_alert_form').slideDown('fast');
     }
}

/*
 * Permet l'envoi du mail qui confirme l'enregistrement de sont email, pour alerter la personne quand le produit sera disponible
 */
function sendMailAlertStock (type_alert, form, itm_rr_id) {

    itm_rr_id = itm_rr_id !== undefined ? itm_rr_id : '';
    var id= form.getValue('produit_id');
    var is_achat_express = $('#is_achat_express_v2').val() !== undefined;
    var elem = is_achat_express ? '_' + id + itm_rr_id : '';

    var $email = $('#mail_alerte_stock' + elem).val(),
        $produit_id = $('#produit_principal' + elem).val(),
        $taille_id = $("#tailleProd" + elem).val(),
        $couleur_id = $("#couleurProd" + elem).val(),
        $type_alert = type_alert;

    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    var mail_value = $('#mail_alerte_stock' + elem).val();
    $verif_mail = mail_value.match(mailformat);

    if ($email == '' || !$verif_mail) {

        $('#mail_alerte_stock' + elem).addClass('inputErr');
    } else {

        $('#mail_alerte_stock' + elem).removeClass('inputErr');
        $.ajax({
            type : 'post',
            url : path_relative_root + create_link('ajax_mail_alert_stock'),
            data : {
                email : $email,
                produit_id : $produit_id,
                taille_id : $taille_id,
                couleur_id : $couleur_id,
                type_alert : $type_alert
            },
            success : function(response) {
                $('.bloc_add_alert' + elem + ' .alert_stock').hide();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_erreur').hide();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_form').hide();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_confirmation').show();
            },
            fail : function () {
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_confirmation').hide();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_erreur').show();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_form').show();
            }
        });
    }
}

function closealertloginerror() {
    $("#alertboxlogin").slideUp(function() {
        $(this).remove();
        $("#shade").fadeOut(function() {
            $(this).remove();
        });
    });
}

function closealerterror() {
    $("#alertbox1").slideUp(function() {
        $(this).remove();
        $("#shade").fadeOut(function() {
            $(this).remove();
        });
    });
}

// popup PDV
function showpdv() {

    var shade, modbox, contents;

    $.ajax({
        type : 'post',
        url : path_relative_root + create_link('ajax_search_shop'),
        success : function (res) {

            if (res) {

                shade = document.getElementById('shad');
                modbox = document.getElementById('pdvBox');

                contents = $('<div />').html(res);

                // Make sure modbox will show on top of shade
                $(shade).after(modbox);

                // Inject store locator into modbox
                $('.store_locator', modbox).html($('.storeloc_form', contents).add($('.liste_recherche_distributeur', contents)));

                initialize();

                // Show modbox and make sure closing routine won't fire more than once
                $(shade).off('click', closepdv).fadeIn(200, function () {

                    $(modbox).slideDown(600);
                    $(shade).on('click', closepdv);

                    // Init locator
                    if (navigator.geolocation) {
                        var crd = navigator.geolocation.getCurrentPosition(rechercheProximite);
                    }
                });
            }
        }
    });
}

function closepdv() {

    var shade = document.getElementById('shad');
    var modbox = document.getElementById('pdvBox');

    // Hide modbox and shade and destroy shade's click event
    $(modbox).slideUp(600, function () {

        $(shade).off('click', closepdv).stop(true, true).fadeOut(200);
    });
}

$('.product_feats_wrapper, .form_submit.dispo_magasin,  #chez_vous_le, .share_wrapper').appendTo('.product_info_wrapper');

$('.prod_top_wrapper').fadeIn();

// /*Page sliders*/
if($("#visual_scroller").length <= 3) {

    var productThumbs = new Swiper('#visual_scroller', {
        slidesPerView: 'auto',
        spaceBetween: 22,
        direction: 'vertical',
        freeMode: true,
        loop:false,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        navigation: {
            nextEl: '.thumbNext',
            prevEl: '.thumbPrev',
        },
        breakpoints: {
            1160: {
                direction: 'horizontal',
                slidesPerView: 4,
                spaceBetween: 15,
                loop:false,
            },
        },
    });
}

if($("#visual_scroller").length > 3) {

    var productThumbs = new Swiper('#visual_scroller', {
        slidesPerView: 'auto',
        spaceBetween: 22,
        direction: 'vertical',
        freeMode: true,
        loop:true,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        navigation: {
            nextEl: '.thumbNext',
            prevEl: '.thumbPrev',
        },
        breakpoints: {
            1160: {
                direction: 'horizontal',
                slidesPerView: 4,
                spaceBetween: 15,
                loop:false,
            },
        },
    });
}

if($("#visual_scroller .swiper-slide").length < 4) {
    $('.more_vis').addClass('few_pics');
}

if($(".product_page .main_vis_fp_swiper .swiper-slide").length <= 3) {
    var main_productVisu = new Swiper('.product_page .main_vis_fp_swiper', {
        direction: 'vertical',
        slidesPerView: 1,
        mousewheel: true,
        loop: false,
        navigation: {
            nextEl: '.thumbNext',
            prevEl: '.thumbPrev',
        },
        thumbs: {
            swiper: productThumbs
        },

        breakpoints: {
            1160: {
                direction: 'horizontal',
            },
        },
        preloadImages: false,
        lazy: true,
        loadPrevNext: true,
        loadOnTransitionStart: true


    });
}

if($(".product_page .main_vis_fp_swiper .swiper-slide").length > 3) {
    var main_productVisu = new Swiper('.product_page .main_vis_fp_swiper', {
        direction: 'vertical',
        slidesPerView: 1,
        mousewheel: true,
        loop: true,
        navigation: {
            nextEl: '.thumbNext',
            prevEl: '.thumbPrev',
        },
        thumbs: {
            swiper: productThumbs
        },

        breakpoints: {
            1160: {
                direction: 'horizontal',
            },
        },
        preloadImages: false,
        lazy: true,
        loadPrevNext: true,
        loadOnTransitionStart: true
    });
}

$('.product_page .main_vis_fp_swiper .swiper-slide').click(function(){
   $('body').css('overflow', 'hidden');
});


 $('#zoom_box_wrapper .swiper-slide, #zoomBox .close_pop').click(function(){
    $('body').css('overflow', 'auto');
 });

 $('#bloc_add_alert #btn_add_alert').click(function(){
   $(this).hide();
 });

 $('.bloc_add_alert_confirmation button').click(function(){
    $('#bloc_add_alert #btn_add_alert').show();
 });

 if($(".productSwiper.swiper-container").length && $('body').hasClass('product_page')) {
     var productSwiper_fp_hp = new Swiper(".productSwiper.swiper-container", {
         slidesPerView: 1,
         observer: true,
         observeParents: true,
         navigation: {
             nextEl: '.pas-button-next',
             prevEl: '.pas-button-prev',
         },
         preloadImages: false,
         lazy: true,
         loadPrevNext: true,
         loadOnTransitionStart: true
     });
 }
 

$(window).on('load resize', function () {
    // Gondole
    window.setTimeout(function() {
        var w = $(window).width();
        if(w < 1366) {
             $('#gondole_aimerez').addClass('small_swiper');
             
            if($("#productAssociationSwiper .swiper-slide.item").length > 2) {
                 $('#productAssociationSwiper').addClass('swiper_on');
               
            } else {
                $('#productAssociationSwiper').addClass('no_swiper');
                $('#productAssociationSwiper').removeClass('swiper_on');
            }
        }
        else {
            if($("#productAssociationSwiper .swiper-slide.item").length > 4) {
                $('#gondole_aimerez').removeClass('small_swiper');
                $('#productAssociationSwiper').addClass('swiper_on');
            } else {
                $('#productAssociationSwiper').addClass('no_swiper');
                $('#productAssociationSwiper').removeClass('swiper_on');
            }
        }

    }, 1000);
});


 /**
  * Disable add to cart btn and display a rollover text information.
  */
 function disableAddCart() {

     "use strict";

     var addCartWrapper = $(".btnAddBasketWrapper");
     var addCartBtn = $(".btnAddBasket");

     addCartWrapper.addClass("inactiv");
     addCartWrapper.removeClass("active_btn");
     addCartBtn.prop("disabled", true);
     $( "#sizeerror" ).css('opacity', '1');
 }

 /**
  * Enable add to cart btn
  */
 function enableAddCart() {

     "use strict";

     var addCartWrapper = $(".btnAddBasketWrapper");
     var addCartBtn = $(".btnAddBasket");
     var disabledBtnInfos = $(".disabledBtnInfos");

     disabledBtnInfos.text("");
     addCartWrapper.removeClass("inactiv");
     addCartWrapper.addClass("active_btn");
     addCartBtn.prop("disabled", false);

     $( "#sizeerror" ).css('opacity', '0');
 }

window.setTimeout(function() {
     $("#bloc_add_basket.inactiv, #addToWishlistButton").click(function(){
         $("html, body").delay(100).animate({
             scrollTop: $('#itm_name').offset().top
         }, 2000);
     });

    $( ".prod_btn_wrapper .inactiv" )
    .mouseover(function() {
        $( "#sizeerror:not(.active_btn)" ).addClass('show');
    })
    .mouseout(function() {
        $( "#sizeerror" ).removeClass('show');
    });

 }, 1000);


/***********
FP Color
***********/
$(document).ready(function() {

    $('#ligne_couleur').mouseover(function(e) {
    e.preventDefault();
        $('#ligne_couleur .js-dropp-action').addClass('drop_button');
        $('.choices_list.choice_color').addClass('js-visible');
        $('#ligne_couleur .drop_btn_big:not(".no_dropdown")').addClass('opened');
        $('.choices_list.size').removeClass('js-visible');
        $('#ligne_pointure .js-dropp-action').removeClass('drop_button');
        $('#ligne_couleur').removeClass('closed');
    });

    $('#ligne_couleur').mouseout(function(){
        $('#ligne_couleur .js-dropp-action').removeClass('drop_button');
        $('.choices_list.choice_color').removeClass('js-visible');
        $('#ligne_couleur').addClass('closed');

        window.setTimeout(function() {
            $('#ligne_couleur.closed .simplebar-content-wrapper').animate({ scrollTop: 0 }, "fast");
        }, 1500);
    });

    // Using as fake input select dropdown
    $('#ligne_couleur input').click(function() {
        $(this).addClass('js-visible').siblings().removeClass('js-visible');
        $('.choices_list.color,#ligne_couleur .js-dropp-action').removeClass('js-visible');
        $('#ligne_couleur .drop_btn_big').removeClass('opened');
        $(this).parent().addClass('active').siblings().removeClass('active');
    });

    $('.choices_list.choice_color .input_check').click(function() {
        $('#ligne_couleur .dropp-header__btn').removeClass('drop_button');
        $('.choices_list.choice_color.js-visible').removeClass('js-visible');
        $('#ligne_couleur .drop_btn_big').removeClass('opened');
        $('.choices_list.choice_color').addClass('color_selected');
    });

    $('#ligne_couleur .dropp-header__btn').click(function() {
        $('.choices_list.choice_color.color_selected').removeClass('color_selected');
    });

    $('#ligne_couleur .prod_listes .choices_list .form_itm.color input:checked').parent('.form_itm').addClass('active');

    window.setTimeout(function() {
        $('#ligne_couleur .dropp .choices_list .form_itm.color').css('opacity','1');
    }, 500);
});