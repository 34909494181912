alert = (function() {
    "use strict";

    var shade, albox, alert_tmpl;
    var regex = new RegExp("\\s", "g");

    function init() {
        shade = document.getElementById("shad_abox");
        albox = document.getElementById("abox");

        alert_tmpl = albox.innerHTML.trim();

        window.removeEventListener("load", init);
    }

    function closeAlertBox() {
        $(albox).fadeOut(400, function() {
            $("#abox .form_submit").css("display", "block");
        });
        $(shade)
            .off("click", closeAlertBox)
            .fadeOut(600);
        $("body").removeClass("alert_open");
    }

    function alert(str, replaceStrSubmit = null) {
        $("body").addClass("alert_open");

        $(".txt_alert", albox).html(str);

        if (replaceStrSubmit !== null) {
            $("#abox .form_submit span").html(replaceStrSubmit);
        }

        $(".close", albox).on("click", closeAlertBox);
        $(shade).one("click", closeAlertBox);

        window.scroll(0, 0);

        $(shade).css("display", "block");
        $(shade).animate(
            {
                opacity: 0.7,
            },
            200
        );
        $(albox).fadeIn(400);
    }

    window.addEventListener("load", init);

    return alert;
})();
