// Récupération de la valeur de la constante NO_LAZYLOAD pour appliquer le paramètre aux swiper
var isLazyLoad = (typeof is_lazy_load !== 'undefined' && is_lazy_load == false) ? false : true;

$(function () {
    // Le scroll vers le produit
    if ($("#scroll_items").length > 0) {
        var element_id = document.location.hash.substr(3);

        if (element_id !== "" && typeof element_id !== 'undefined') {
            $("html, body").animate(
                {
                    scrollTop: $("#itm-" + element_id).offset().top - 120, // 120 hauteur du menu en scroll
                },
                500
            );
        }
    }

    // Gestion du shad pour le menu
    $(".my_account.is_logged").on("mouseenter", function () {
        $(this).addClass('hover');
        $("#shad_menu").addClass("actif");
    });

    $(".my_account.is_logged").on("mouseleave", function () {
        $(this).removeClass('hover');
        $("#shad_menu").removeClass("actif");
    });

    $(".my_basket").on("mouseenter", function () {
        topCartAppear();
    });

    $(".my_basket").on("mouseleave", function () {
        topCartDesappear();
    });

    var menu_timeout = setTimeout(null);

    $(".main_menu_itm")
        .not(".menu_itm_hover")
        .on({
            mouseenter: function () {
                var mainmenu = $(".main_menu");

                clearTimeout(menu_timeout);

                mainmenu.addClass("actif");
            },
            mouseleave: function () {
                menuItemClose();
            },
        });

    $(".menu_itm_hover").on("mouseenter", function (evt) {
        var mainmenu = $(".main_menu");
        var submenu = $(".submenu_wrapper", this);

        evt.preventDefault();

        clearTimeout(menu_timeout);

        if (!mainmenu.hasClass("actif")) {
            $("#shad_menu").addClass("actif");

            mainmenu.addClass("actif");

            menu_timeout = setTimeout(function () {
                submenu.stop(true, true).fadeIn(200);
            }, 200);
        } else {
            submenu.show();
        }
    });

    function menuItemClose(submenu) {
        clearTimeout(menu_timeout);

        if (submenu) {
            submenu.stop(true, true).fadeOut(200);
        }

        menu_timeout = setTimeout(function () {
            $("#shad_menu").removeClass("actif");
            $(".main_menu").removeClass("actif");
        });
    }

    $(document).on("scroll", function () {
        menuItemClose($(".submenu_wrapper:visible"));
    });

    $(".menu_itm_hover").on("mouseleave", function () {
        menuItemClose($(".submenu_wrapper", this));
    });

    $('.lang_switch').on({
        mouseenter: function () {
            $('.lang_itm:not(.active)').addClass('show');
        },
        mouseleave: function () {
            $('.lang_itm:not(.active)').removeClass('show');
        },
    });

    $('#trigger_recherche').on("click touch", function () {
        $('.wrap_search_field').fadeIn().css("display", "flex");
        $('#shad_menu').addClass('actif');
    });

    $('.close_search').on("click touch", function () {
        closeRecherche();
    });

    $("#search_home").on('input', function () {
        var value = $("#search_home").val();

        if (value.length >= 3) {
            $('body').addClass('search_in');
        } else {
            $('body').removeClass('search_in');
        }
    });

    $(function () {
        if (getCookie("cookie_header_delivery") != "" && $('body').hasClass('category')) {
            $('#rayon_sticky_block').addClass('reposition');
        }

        if ($('#rayon_sticky_block .filter_wrapper').length > 3) {
            $('#rayon_sticky_block').addClass('reposition_resp');
        }

        //APPEND ADDWISHLIST BTN TO PLACE IT CORRECTLY
        if ($('.category .wishlistBtn.addToWishlistButton').length) {
            $('#list_item .item .wishlistBtn.addToWishlistButton').each(function () {
                $(this).appendTo($(this).closest('.item'));
                $(this).css('opacity', '1');
            });
        }
    });

    if ($(".promo_swiper .swiper-slide").length > 0) {
        promoSwiper = new Swiper(".promo_swiper", {
            centeredSlides: true,
            loop: false,
            slidesPerView: 'auto',
            navigation: {
                nextEl: '.navpromo.promoNext',
                prevEl: '.navpromo.promoPrev',
            },
            speed: 1500,
            autoplay: {
                delay: 4500,
                disableOnInteraction: false,
            },
            autoplayDisableOnInteraction: false
        });
    }

    if ($("#header_cover .swiper-slide").length) {
        header_cover = new Swiper("#header_cover", {
            slidesPerView: 1,
            loop: true,
            navigation: {
                nextEl: '.super_home_swiper_nxt',
                prevEl: '.super_home_swiper_prv',
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
                type: 'fraction',
                renderFraction: function (currentClass, totalClass) {
                    return '<span class="' + currentClass + '"></span>' + ' <span>-</span> ' + '<span class="' + totalClass + '"></span>';
                }
            },


        });
    }

    if ($(".pushProductHomeWrapper .item.swiper-slide").length > 1) {
        pushProductHomeWrapper = new Swiper(".pushProductHomeWrapper", {
            slidesPerView: 1,
            loop: false,
            navigation: {
                nextEl: '.swiper-pushProduct-button-next',
                prevEl: '.swiper-pushProduct-button-prev',
            },
            pagination: {
                el: '.swiper-pagination_2',
                clickable: true,
                type: 'fraction',
                renderFraction: function (currentClass, totalClass) {
                    return '<span class="' + currentClass + '"></span>' + ' <span>-</span> ' + '<span class="' + totalClass + '"></span>';
                }
            },


        });
    }

    if ($(".pushProductHomeWrapper .item.swiper-slide").length >= 2) {
        $('.button_and_pagination').show();
    }

    if ($("#coverSwiper .swiper-slide").length) {
        header_cover2 = new Swiper("#coverSwiper", {
            slidesPerView: 1,
            loop: true,
            speed: 1500,
            navigation: {
                nextEl: '.super_home_swiper_nxt',
                prevEl: '.super_home_swiper_prv',
            },
            autoplay: {
                delay: 5000,
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
                type: 'fraction',
                renderFraction: function (currentClass, totalClass) {
                    return '<span class="' + currentClass + '"></span>' + ' <span>-</span> ' + '<span class="' + totalClass + '"></span>';
                }
            },
        });
    }

    if ($('#coverSwiper .swiper-slide').length) {
        header_cover2.on('slideChangeTransitionStart', function () {
            if ($('.swiper-slide-active').find('.slide_video').length) {
                var video = $('#coverSwiper .swiper-slide-active iframe');
                var source = video.attr('src');
                video.attr('src', '');

                setTimeout(function () {
                    video.attr('src', source);
                }, 5);
            }
        });
    }

    $("#coverSwiper").each(function (elem, target) {
        var swp = target.swiper;
        $(this).hover(function () {
            swp.autoplay.stop();
        }, function () {
            swp.autoplay.start();
        });
    });

    if ($('.wrapper_bandeau').length) {
        $('.wrapper_bandeau .wrap_bg').css("opacity", "1");
    }

    //Seo textes rayons
    if ($('.txt_bandeau').length) {
        setTimeout(function () {
            readMore();
        }, 300);
    }

    if ($('.cart.step_1').length) {
        $('.product_quantity_change').each(function () {
            var parentContainer = $(this).parent().parent();
            $(this).appendTo(parentContainer.find('.cart_product_sizecol.quantity'));

            setTimeout(function () {
                $('.product_quantity_change').each(function () {
                    $(this).css('opacity', '1');
                });
            }, 300);
        });
    }

    $('.misc_cookiesinfo .content_sat .cookieShowLightbox').click(function() {
        $('.misc_cookiesinfo #cookiesLightbox').addClass('displayCookies');
        if($('.misc_cookiesinfo #cookiesLightbox').has('.hideCookies')) {
            $('.misc_cookiesinfo #cookiesLightbox').removeClass('hideCookies');
        }
        $('.misc_cookiesinfo #cookiesLightbox .w-form-line.w-submit button.w-loader').addClass('hideLoader');
    });

    $('.misc_cookiesinfo #js_cookie_refuse, .misc_cookiesinfo #js_cookie_accept').click(function() {
        if($('.misc_cookiesinfo #cookiesLightbox').has('.displayCookies')) {
            setTimeout(() => {
                $('.misc_cookiesinfo #cookiesLightbox').removeClass('displayCookies');
                $('.misc_cookiesinfo #cookiesLightbox').addClass('hideCookies');
                $('.misc_cookiesinfo #shadCookie').removeClass('actif');
            }, 500);
        }
    });
});

/**
 * Pour afficher le panier sur le coté (utilisé aussi dans product.js à l'ajout du panier)
 */
function topCartAppear() {
    var show_top_cart_parent = $('.my_basket');
    var count_bask = $('#cart_top .cart').attr('data-cart');

    show_top_cart_parent.addClass("hover");
    $('#shad_menu').addClass("actif");
}

/**
  * Pour afficher le panier sur le coté (utilisé aussi dans product.js à l'ajout du panier)
  */
function topCartDesappear() {
    var show_top_cart_parent = $('.my_basket');
    show_top_cart_parent.removeClass("hover");
    $('#shad_menu').removeClass("actif");
}

function closeRecherche() {
    if ($('.wrap_search_field').is(":visible")) {
        $('.wrap_search_field').fadeOut();
        $('#shad_menu').removeClass('actif');
    }
}

$('#search_home').keypress(function (e) {
    var key = e.which;

    if (key == 13 && $('#search_home').val().length >= 3) {
        closeRecherche();
    }
});

$('#wrapper_moncompte .w-form-line.w-submit:last-of-type button').wrapAll('<div class="form_btn"></div>');
$('.wrapper_moncompte .w-form-line.w-submit:last-of-type button').wrapAll('<div class="form_btn"></div>');

$('.wrapper_moncompte form img.valid_form').parent().css('position', 'relative');

function btnLoaderTrigger(evt) {

    "use strict";

    var element = $(this);
    var loader = $(this).next(".loader");

    if (loader.length > 0) {

        evt.preventDefault();

        loader.removeAttr("style").parent().addClass("loading");
        loader.removeClass("cache");
        loader.addClass("loading");

        setTimeout(function () {
            loader.parent().removeClass("loading");
            loader.addClass("cache");
            loader.removeClass("loading");
        }, 1200);

        if ($(this).attr("href")) {

            window.location.assign($(this).attr("href"));
        }

        // Dans le cas où il y a plusieurs bouton  en loading
        window.setTimeout(function () {
            $('.form_submit.loading').removeClass('loading')
        }, 5000);
        // Not sure what it's for but this triggers an infinite loop
        // window.setTimeout($.fn.trigger.bind(element), 400, "click");
    }
}

/**
 *	New functions (they are supposed to be at least a little bit cleaner than legacy code)
 */

//Below lies the legacy code (enter at your own risk)
function checkdate(d, m, y) {
    return (
        m > 0 &&
        m < 13 &&
        y > 0 &&
        y < 32768 &&
        d > 0 &&
        d <= new Date(y, m, 0).getDate()
    );
}
var nav_UA = window.navigator.userAgent.split("/"),
    nav_ver = parseFloat(nav_UA.pop()),
    nav_name = nav_UA.pop();

if (
    (nav_name.indexOf("safari") !== -1 && nav_ver <= 525) ||
    (nav_name.indexOf("firefox") !== -1 && nav_ver === 1.8)
) {
    function _dom_trackActiveElement(evt) {
        if (evt && evt.target) {
            document.activeElement = evt.target == document ? null : evt.target;
        }
    }
    function _dom_trackActiveElementLost(evt) {
        document.activeElement = null;
    }
    if (!document.activeElement) {
        document.addEventListener("focus", _dom_trackActiveElement, true);
        document.addEventListener("blur", _dom_trackActiveElementLost, true);
    }
}
/**/
var id_select = "";

//-----------------------------------------------------------------

//D�finit les classes initiales pour les elements d'un formulaire
function setInitialClassName(elementIdArray) {
    for (i = 0; i < elementIdArray.length; i++) {
        var elementInput = document.getElementById(elementIdArray[i]);
        var elementLabel = document.getElementById(elementIdArray[i] + "Label");
        if (elementInput && elementInput.type != "hidden") {
            elementInput.className = "inputForm";
            if (elementLabel) elementLabel.className = "labelForm";
        }
    }
}

//D�finit les classes initiales pour les elements de date d'un formulaire
function setInitialDateClassName(elementIdArray) {
    for (i = 0; i < elementIdArray.length; i++) {
        var elementInputDay = document.getElementById(elementIdArray[i] + "J");
        var elementInputMonth = document.getElementById(
            elementIdArray[i] + "M"
        );
        var elementInputYear = document.getElementById(elementIdArray[i] + "A");
        var elementLabel = document.getElementById(elementIdArray[i] + "Label");
        if (
            elementInputDay &&
            elementInputDay.type != "hidden" &&
            elementInputMonth &&
            elementInputMonth.type != "hidden" &&
            elementInputYear &&
            elementInputYear.type != "hidden"
        ) {
            elementInputDay.className = "inputForm";
            elementInputMonth.className = "inputForm";
            elementInputYear.className = "inputForm";
            if (elementLabel) elementLabel.className = "labelForm";
        }
    }
}

// V�rification d'un bouton radio
function verif_radio(elementID, fieldname) {
    if (
        elementID == "clientCiviliteM" ||
        elementID == "clientCiviliteMme" ||
        elementID == "clientCiviliteMlle"
    )
        elementID = "clientCivilite";

    if (
        elementID == "optin_y" ||
        elementID == "optin_n"
    )
        elementID = "optin";
    var objElement = document.getElementsByName(elementID);
    var objLabel = document.getElementById(elementID + "Label");
    var erreurText = document.getElementById("erreur_msg");
    var trouve = false;
    $(objElement)
        .removeData("data-error-text")
        .removeAttr("data-error-text");
    if (elementID) {
        $("input[name='" + elementID + "']").each(function (idx) {
            if ($(this).is(":checked") == true) {
                objElement.className = "inputForm";
                trouve = true;
            }
        });
        if (!trouve) {
            if (elementID == "clientCivilite") {
                t_error =
                    '<p class="title_toolltip">' +
                    ajax_file("ajax_sprintf.php?arg1=" + fieldname) +
                    " :</p>" +
                    ajax_file(
                        "ajax_sprintf.php?arg1=js_error_civilite&arg2=" +
                        fieldname
                    );
            } else {
                t_error =
                    '<p class="title_toolltip">' +
                    ajax_file("ajax_sprintf.php?arg1=" + fieldname) +
                    " :</p>" +
                    ajax_file(
                        "ajax_sprintf.php?arg1=js_error_thanks&arg2=" +
                        fieldname
                    );
            }

            $(objElement).data("data-error-text", t_error);
            erreurText.innerHTML = t_error;
            $(objElement).addClass("inputErr");
            $(objLabel).addClass("labelErr");
            return false;
        } else {
            $(objElement).removeClass("inputErr");
            $(objLabel).removeClass("labelErr");
        }
    }
    return true;
}

function verif_price(e, t) {
    var a = document.getElementById(e),
        r = document.getElementById(e + "Label"),
        l = (document.getElementById("mes_err_fieldset"),
            document.getElementById("erreur_msg"));
    if (
        ($(a)
            .removeData("data-error-text")
            .removeAttr("data-error-text"),
            "" == a.value)
    )
        return (
            (t_error =
                '<p class="title_toolltip">' +
                ajax_file("ajax_sprintf.php?arg1=" + t) +
                " :</p>" +
                ajax_file("ajax_sprintf.php?arg1=js_thanks_precise&arg2=" + t)),
            $(a).data("data-error-text", t_error),
            (l.innerHTML = t_error),
            (a.className = "inputErr"),
            (r.className = "labelErr"),
            !1
        );
    var n = validate_price(a.value);
    return 0 == n
        ? ((t_error =
            '<p class="title_toolltip">' +
            ajax_file("ajax_sprintf.php?arg1=" + t) +
            " :</p>" +
            ajax_file("ajax_sprintf.php?arg1=js_thanks_precise&arg2=" + t)),
            $(a).data("data-error-text", t_error),
            (l.innerHTML = t_error),
            (a.className = "inputErr"),
            (r.className = "labelErr"),
            !1)
        : ((a.className = "inputForm"), (r.className = "labelForm"), !0);
}

function validate_price(e) {
    return /^[\d\.,]+$/.test(e);
}

//V�rificartion d'une case coch�
function verif_checkbox(elementName, fieldname) {
    var objElement = document.getElementsByName(elementName);
    var objLabel = document.getElementById(elementName.slice(0, -2) + "Label");
    var erreurFieldset = document.getElementById("mes_err_fieldset");
    var erreurText = document.getElementById("erreur_msg");
    if (elementName) {
        objElement.className = "inputForm";
        objLabel.className = "labelForm";
        for (i = 0; i < objElement.length; i++)
            if (objElement[i].checked == true) {
                objElement.className = "inputForm";
                objLabel.className = "labelForm";
                return true;
            }
        erreurFieldset.style.display = "block";
        erreurText.innerHTML = ajax_file(
            "ajax_sprintf.php?arg1=js_error_thanks&arg2=" + fieldname
        );
        objElement.className = "inputErr";
        objLabel.className = "labelErr";
        return false;
    }
    return true;
}

function btnLoaderUnloading(btn) {

    "use strict";

    var loader = btn.next(".loader");

    if (loader.length > 0) {

        setTimeout(function () { loader.removeAttr("style").parent().removeClass("loading") }, 200);
    }
}

function verif_checkbox_index(elementName, fieldname, pIndex) {
    var erreurFieldset = document.getElementById("mes_err_fieldset");
    var erreurText = document.getElementById("erreur_msg");
    if (document.prodForm.visuStatutDetail1) {
        if (document.prodForm.visuStatutDetail1[0].checked == true) {
            return true;
        }
        erreurFieldset.style.display = "block";
        erreurText.innerHTML = ajax_file(
            "ajax_sprintf.php?arg1=js_error_photo_validate"
        );
        return false;
    } else {
        erreurFieldset.style.display = "block";
        erreurText.innerHTML = ajax_file(
            "ajax_sprintf.php?arg1=js_error_photo_validate"
        );
        return false;
    }
}


function createCookie(name, value, days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toGMTString();
    }
    document.cookie = name + "=" + value + expires + "; path=/";
}

function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1, c.length);
        }
        if (c.indexOf(nameEQ) == 0) {
            return c.substring(nameEQ.length, c.length);
        }
    }
    return null;
}

function eraseCookie(name) {
    createCookie(name, "", -1);
}

$(document).ready(function () {
    if (readCookie("nb_produits") !== null) {
        // view4
        if (readCookie("nb_produits") == 4) {
            if ($('#list_item').hasClass('bydefault'))
                $('#list_item').removeClass('bydefault');
            $('#list_item').addClass('byFour');

            $('#viewChanger .default').removeClass('actif');
            $('#viewChanger .byFour').addClass('actif')

        } else if (readCookie("nb_produits") == 2) {
            //Default view
            if (!$('#list_item').hasClass('bydefault'))
                $('#list_item').removeClass('byFour');
            $('#list_item').addClass('bydefault');

            $('#viewChanger .byFour').removeClass('actif');
            $('#viewChanger .default').addClass('actif');

        }
    }
});

function changeVisu(field, path, evt) {
    var new_img, target, current;

    field = document.getElementById(field);

    if (evt) {
        target = evt.target.parentElement;
        current = target.parentElement.getElementsByClassName("current");

        target.classList.add("current");

        Array.prototype.slice.call(current).forEach(function (elm) {
            if (elm !== target) {
                elm.classList.remove("current");
            }
        });
    }

    if (field && field.parentElement) {
        new_img = new Image();

        new_img.id = field.id;
        new_img.className = field.className;

        new_img.addEventListener("load", function () {
            this.alt = field.alt;

            this.setAttribute("width", this.naturalWidth);
            this.setAttribute("height", this.naturalHeight);

            if (field.parentElement) {
                field.parentElement.appendChild(this);
                field.parentElement.removeChild(field);
            }
        });

        new_img.src = path;
    }

    var productSwiper = new Swiper(".productSwiper", {
        slidesPerView: 1,
        observer: true,
        observeParents: true,
        navigation: {
            nextEl: '.rollover_rayonsNext',
            prevEl: '.rollover_rayonsPrev',
        },
        preloadImages: false,
        lazy: isLazyLoad,
        loadPrevNext: true,
        loadOnTransitionStart: true
    });
}

function getPos(obj) {
    var pos = { x: obj.offsetLeft || 0, y: obj.offsetTop || 0 };
    while ((obj = obj.offsetParent)) {
        pos.x += obj.offsetLeft || 0;
        pos.y += obj.offsetTop || 0;
    }
    return pos;
}

function closeInfobulle(id) {
    if (!jQuery.browser.msie) $("#" + id).fadeOut(400);
    else document.getElementById(id).style.display = "none";
    document.getElementById(id).style.zIndex = "0";
}

var one_time = false;
function openInfobulle(id, type, elem) {
    window.clearTimeout(timer);
    $("#bloc_erreur").css("display", "none");
    if (!jQuery.browser.msie)
        $("#bloc_erreur")
            .stop(false, true)
            .fadeOut();
    if (!jQuery.browser.msie) $("#" + id).fadeIn(400);
    else document.getElementById(id).style.display = "block";
    if (typeof type != "undefined") {
        var margin_spe_left = 0;
        var margin_spe_top = 0;
        if (elem.nodeName == "SELECT") {
            id = elem.id.substr(0, elem.id.length - 1);
            if (
                id == "clientMailAuth" ||
                id == "clientPwdAuth" ||
                id == "mailLost"
            ) {
                var txt_spe = document.getElementById(id + "ErrErr").innerHTML;
                margin_spe_left = 300;
                if (id == "clientPwdAuth") margin_spe_top = 10;
                infos_alias;
            } else if (type == "banque")
                var txt_spe = document.getElementById("infos_carte").innerHTML;
            else if (type == "alias_banque")
                var txt_spe = document.getElementById("infos_alias").innerHTML;
            else var txt_spe = document.getElementById(id + "Err").innerHTML;
        } else {
            if (
                elem.id == "clientMailAuth" ||
                elem.id == "clientPwdAuth" ||
                elem.id == "mailLost"
            ) {
                if (document.getElementById(elem.id + "ErrErr"))
                    var txt_spe = document.getElementById(elem.id + "ErrErr")
                        .innerHTML;
                if (elem.id == "mailLost") margin_spe_left = -350;
                else margin_spe_left = -340;
                if (elem.id == "clientPwdAuth") margin_spe_top = 41;
                else margin_spe_top = 4;
            } else if (type == "banque")
                var txt_spe = document.getElementById("infos_carte").innerHTML;
            else if (type == "alias_banque")
                var txt_spe = document.getElementById("infos_alias").innerHTML;
            else
                var txt_spe = document.getElementById(elem.id + "Err")
                    .innerHTML;
        }
        document.getElementById("erreur_msg").innerHTML = txt_spe;
        pos = getPos(elem);
        document.getElementById("bloc_erreur").style.position = "absolute";
        if (type == "banque") {
            margin_spe_left -= 42;
            margin_spe_top += 30;
        } else if (type == "alias_banque") {
            margin_spe_left -= 42;
            margin_spe_top += 15;
        }
        document.getElementById("bloc_erreur").style.top =
            pos.y -
            (document.getElementById("bloc_erreur").offsetHeight +
                margin_spe_top -
                2) +
            "px";
        pos_left_margin = getPos(document.getElementById("content"));
        left_add = 0;
        if (document.getElementById("wrapper_anniversaire_jour1")) {
            left_add = document.getElementById("bloc_erreur").offsetWidth;
            left_add -= 35;
        }
        document.getElementById("bloc_erreur").style.left =
            pos.x +
            (elem.offsetWidth - 8) +
            margin_spe_left +
            27 -
            left_add +
            "px";
        if (type == "banque" || type == "alias_banque") {
            document.getElementById("bloc_erreur").style.width = "280px";
        } else {
            document.getElementById("bloc_erreur").style.width = "280px";
        }
        document.getElementById("bloc_erreur").style.zIndex = "10000";
    }
}

function OverPopup(elem, msg, type, is_vp) {
    clearTimeout(timer);

    if (type == "visible") {
        if (typeof is_vp != "undefined" && is_vp != "cmpid_adwords") {
            $("#bloc_erreur_vp").css("display", "none");
            if (!jQuery.browser.msie) {
                $("#bloc_erreur_vp")
                    .stop(false, true)
                    .fadeOut();
            }
            if (!jQuery.browser.msie) {
                $("#bloc_erreur_vp").fadeIn(400);
            } else {
                document.getElementById("bloc_erreur_vp").style.display =
                    "block";
            }
        } else {
            if (
                !document.getElementById("formAnniversaire") ||
                (document.getElementById("formAnniversaire") &&
                    elementID != "newsletterMailv" &&
                    elementID != "newsletterMailInscrit")
            ) {
                pos = getPos(elem);
                document.getElementById("erreur_msg").innerHTML = msg;
                if (!jQuery.browser.msie) {
                    $("#bloc_erreur")
                        .stop(true, true)
                        .fadeIn(400);
                } else {
                    document.getElementById("bloc_erreur").style.display =
                        "block";
                }
                document.getElementById("bloc_erreur").style.position =
                    "absolute";
                pos_left_margin = -13;
                var left_add = 17;
                if (document.getElementById("wrapper_anniversaire_jour1")) {
                    left_add = document.getElementById("bloc_erreur")
                        .offsetWidth;
                    left_add -= 35;
                }
                if (document.getElementById("formAnniversaire")) {
                    left_add = $("#erreur_msg").width() - 10;
                    $(".bottom_tooltip").attr("align", "right");
                }
                document.getElementById("bloc_erreur").style.left =
                    pos.x +
                    (elem.offsetWidth - 8) -
                    pos_left_margin -
                    left_add +
                    1 +
                    "px";
                if (is_vp === "cmpid_adwords") {
                    var clone = $("#bloc_erreur").clone(),
                        c_pos = {
                            top:
                                $(elem).offset().top -
                                $(elem)
                                    .offsetParent()
                                    .offset().top -
                                $("#bloc_erreur").height() +
                                4,
                            left:
                                $(elem).offset().left -
                                $(elem)
                                    .offsetParent()
                                    .offset().left -
                                $("#bloc_erreur").innerWidth() +
                                $(elem).width() * 2 +
                                6,
                        };
                    $("#bloc_erreur").remove();
                    $(elem).after(clone);
                    $("#bloc_erreur")
                        .find("td:nth-child(2)")
                        .children("img")
                        .css({
                            float: "right",
                            margin: "0 7px",
                        });
                    $("#bloc_erreur").css({
                        position: "absolute",
                        top: c_pos.top,
                        left: c_pos.left,
                        "z-index": "99999",
                        opacity: "1",
                    });
                } else {
                    document.getElementById("bloc_erreur").style.top =
                        pos.y -
                        document.getElementById("bloc_erreur").offsetHeight +
                        "px";
                }
                if (elem.id == "departement_naissance") {
                    $("#bloc_erreur").css({
                        width: 180,
                        marginTop: 63,
                        marginLeft: -310,
                    });
                }
                document.getElementById("bloc_erreur").style.zIndex = "99999";
            }
        }
    } else {
        if (typeof is_vp != "undefined" && is_vp != "cmpid_adwords") {
            if (!jQuery.browser.msie) {
                $("#bloc_erreur_vp").fadeOut(400);
            } else {
                document.getElementById("bloc_erreur_vp").style.display =
                    "none";
            }
            document.getElementById("bloc_erreur_vp").style.zIndex = "0";
        } else {
            if (!jQuery.browser.msie) {
                $("#bloc_erreur")
                    .stop(true, true)
                    .fadeOut(400);
            } else {
                document.getElementById("bloc_erreur").style.display = "none";
            }
            document.getElementById("bloc_erreur").style.zIndex = "0";
        }
    }
}

//  pour les questions du panier panier */
function fadePanier(id, nbelem) {

    for (var i = 1; i <= nbelem; i++) {

        if (id != i && $("#texte_question_" + i).css('display') == 'block') {
            $("#texte_question_" + i).slideUp(400);
            $("#intitule_question_" + i).removeClass('actif');
        }
    }

    if ($("#texte_question_" + id).css('display') != 'block') {

        $("#texte_question_" + id).slideDown(400);
        $("#texte_question_" + id).addClass('actif');
        $("#intitule_question_" + id).addClass('actif');
    } else {

        $("#texte_question_" + id).slideUp(400);
        $("#texte_question_" + id).removeClass('actif');
        $("#intitule_question_" + id).removeClass('actif');
    }
}

function show_popup(elem) {
    openMultiShad(elem);
}

function close_popup(elem) {
    var $modbox = $("#" + elem);
    var $shad = $("#shad");
    $shad.attr("onclick", "");
    $shad.animate({ opacity: 0 }, 350, function () {
        $shad.css({ display: "none", opacity: "" }).data("opened_lb", 0);
    });
    $modbox.css({ display: "none" });
}

function openMultiShad(id) {

    // Show modbox and make sure closing routine won't fire more than once
    $("#" + id).fadeIn(200, function () {

        $("#" + id).slideDown(600);
    });

    if (id !== 'zoomBox') {
        $('body').css('position', 'fixed');
        $("#shad").css("display", "block").fadeTo("slow", 0.8).on("click", function () {
            if (id !== 'selection-offer') {
                closeMultiShad(id);
            }
        });
    }

    if (id === "zoomBox") {
        $('body').css('position', 'fixed');
        $('#zoom_box_wrapper .img_large').on('click', function () {
            closeMultiShad('zoomBox');
        });
    }

    if (id === "sizeguideBox") {
        window.setTimeout(function () {
            $("#wrap_itable .guide_wrapper").overlayScrollbars({});
        }, 200);

        $('body').css('position', 'fixed');
    }

    if (id === "lightbox_achat_express") {
        $('body').css('overflow', 'hidden');
    }

}


function closeMultiShad(id) {
    $("#shad").off("click");

    if ((id === "sizeguideBox" && $('#sizeguideBox').parents('#lightbox_achat_express').length > 0) === false) {
        $('body').css('overflow', '');
        $('body').css('position', '');
    }

    if (id === "sizeguideBox" && $('body').hasClass('product_page')) {
        $('body').css('position', '');
    }

    // Hide modbox and shade and destroy shade's click event
    $("#" + id).fadeOut("slow").queue(function () {
        if ($("#shad").is(":visible")) {
            /**
             * Lookbook page
             * Prevent from removing shad if closing 'guide taille' lightbox
             */
            if (!(id === "sizeguideBox" && $('#sizeguideBox').parents('#lightbox_achat_express').length)) {
                $("#shad").fadeTo("slow", 0, function () {
                    $("#shad").css("display", "none");
                });
            }
        }

        $(this).dequeue();
    });
}

function showDetailOrder(order_id, order_type) {
    if (show_orders_by_type("orders_by_type", order_type, ROOT_PATH))
        $("#order_" + order_id).click();
    $("html, body").animate(
        {
            scrollTop: $('[data-btn-name="order_section"]').offset().top - 100,
        },
        500
    );
}

function changeLocale(locale, uri) {
    $.ajax({
        url: path_relative_root + "ajax_get_uri_by_lang.php",
        type: "post",
        data: { lang: locale, url: uri },
        success: function (data) {
            if (data != "") {
                var force = "";
                if (uri == "/") {
                    force = "?force";
                }

                location.href = data + force;
            }
        },
    });
}

$(function () {
    $('.cartSizeInfoBtn').on('click', function() {
        $(this).next().addClass('loading');
    });
    $("#com_onglet").on("click", ".onglet", function (evt) {
        if ($("a", this).length && !$(this).hasClass("actif")) {
            $(this)
                .addClass("actif")
                .siblings()
                .removeClass("actif");
        }
    });
});

/**
 * Peremt de récupérer les stocks des tailles par couleur ainsi que leur stock
 * @author Timothy Beard / Aurélien Renault
 * @since  2017-09-27
 */
function setAvailableSizes() {
    if (window.json_colisages !== undefined) {
        var sizes_tab = window.json_colisages[$(this).val()];
        var size_box, is_disabled;

        for (size_id in sizes_tab) {
            if (sizes_tab.hasOwnProperty(size_id)) {
                size_box = $('.product_main_wrapper [name="itm_size"]').filter(
                    '[value="' + size_id + '"]'
                );
                is_disabled = !sizes_tab[size_id];
                if (is_disabled) {
                    size_box.addClass('disabled');
                }
                else {
                    size_box.removeClass('disabled');
                }
            }
        }
    }
}

/**select change function **/
$("body").on("change", "select", function () {
    $(this).addClass("changed");
});

$("select").change(function () {
    $(this).addClass("changed");
});

// Show hide div Livraison Gratuit
function cookieHeaderDelivery() {
    var d = new Date();
    d.setTime(d.getTime() + (36500 * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();

    document.cookie = "cookie_header_delivery=accepted;" + expires;
}

function getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

$(function () {
    var headerDelivery = $(".header_mention");

    headerDelivery.remove();

    if (getCookie("cookie_header_delivery") != "") {
        $("#site_head_wrap").removeClass("bandeau_on");
        return;
    } else {
        $("#site_head_wrap").prepend(headerDelivery);
        headerDelivery.slideDown(500);
        headerDelivery.addClass("display");
        $("body").addClass("bandeauDelivery");

        $(".close_delais").on("click", function () {
            cookieHeaderDelivery();
            headerDelivery.slideUp(500, function () {
                headerDelivery.remove();
            });
            $("body").removeClass("bandeauDelivery");
            $("#site_head_wrap").removeClass("bandeau_on");
        });
    }
});

function goBack(toUrl) {
    if (toUrl) {
        window.location.href = path_relative_root;
    } else {
        var url = window.location.href;
        if (url.indexOf("#search") > -1) {
            var new_url = url.substring(0, url.indexOf("#search"));
            window.location.href = new_url;
        } else {
            window.history.back();
        }
    }
}

$(function () {
    if ($('#exit_overlay_OVI.lightbox').css("display") == "block") {
        $("#shad_abox").show();
    }

    $('#exit_overlay_OVI .close').on("click", function () {
        $("#shad_abox").hide();
        $("#exit_overlay_OVI").hide();
    });

});

$(".step_2 .wrapper_panier .left_side .relais.transporteur_Colissimo #choix_relais_relais #searchform .delivery_form_title").replaceWith('<div class="delivery_form_title title_choix_relais">' + Translator.translate('adresse_colissimo_relais') + '</div>');

$('body').on('click', '.search_recap .recap', function () {
    $('#trigger_recherche').click();
    $('#search_home').focus();
});

if ($(".productSwiper.swiper-container").length) {

    $('body').on('mouseenter touchstart', '.productSwiper.swiper-container', function () {

        if ($(this).find('.swiper-slide-on').hasClass('cache')) {

            $(this).find('.swiper-wrapper').html($(this).find('.swiper-slide-on').html());
            $(this).find('.swiper-slide-off').remove();
            $(this).find('.swiper-slide-on').remove();

            var productSwiper = new Swiper($(this), {
                slidesPerView: 1,
                observer: true,
                observeParents: true,
                navigation: {
                    nextEl: '.rollover_rayonsNext',
                    prevEl: '.rollover_rayonsPrev',
                },
                preloadImages: false,
                lazy: isLazyLoad,
                loadPrevNext: true,
                loadOnTransitionStart: true
            });

        }

    });

    //Ajout d'une min-height pour éviter l'effet clignotement
    if ($(".swiper-slide-off").find("span").hasClass('ill_img')) {
        $("#list_item").find($(".item")).css("min-height", "276px");
    }
};


var $deja_compte = Translator.translate('existing_user_pwd_reset');
$(".login_form_wrapper .w-newlogin-form").append($('<p class="deja_compte">' + $deja_compte + '</p>'));



/*********/
/********affichelostpassword********/
var afficheLostPassword = function (evt) {

    var signIn = $('#auth');
    var passWd = $('#resetPass');
    var subLogin = $('#subtitleLogin');
    var subPassword = $('#subtitlePassword');

    evt.preventDefault();

    signIn.animate({
        opacity: 0
    }, 600, function () {

        signIn.css('display', 'none');

        passWd.css('display', '').animate({
            opacity: 1
        }, 600);
    });

    subLogin.animate({
        opacity: 0
    }, 600, function () {

        subLogin.css('display', 'none');
        subPassword.css('display', 'block').animate({
            opacity: 1
        }, 600);
    });
};


$(window).on('load resize', function () {
    // Gondole
    window.setTimeout(function () {
        var w = $(window).width();
        if (w < 1300) {
            $('#tete_gondole').addClass('small_swiper');

            if ($("#productAssociationSwiper .swiper-slide.item").length > 2) {
                var productAssociationSwiper = new Swiper('#productAssociationSwiper', {
                    slidesPerView: 2,
                    spaceBetween: 40,
                    allowTouchMove: false,
                    loop: false,
                    navigation: {
                        nextEl: '.btn_nxt',
                        prevEl: '.btn_prv',
                    },
                });

            } else {
                $('#productAssociationSwiper').addClass('no_swiper');
            }
        }
        else {
            if ($("#productAssociationSwiper .swiper-slide.item").length >= 4) {
                var productAssociationSwiper = new Swiper('#productAssociationSwiper', {
                    slidesPerView: 4,
                    spaceBetween: 55,
                    allowTouchMove: false,
                    loop: false,
                    navigation: {
                        nextEl: '.btn_nxt',
                        prevEl: '.btn_prv',
                    },
                    breakpoints: {
                        1360: {
                            slidesPerView: 3,
                            spaceBetween: 30,
                        },
                        1160: {
                            slidesPerView: 2,
                            spaceBetween: 30,
                        },
                    },
                });

            } else if ($("#productAssociationSwiper .swiper-slide.item").length === 3) {

                $('#productAssociationSwiper').css('max-width', '1260px').addClass('no_w_important');

                var productAssociationSwiper2 = new Swiper('#productAssociationSwiper', {
                    slidesPerView: 3,
                    spaceBetween: 55,
                    allowTouchMove: false,
                    loop: false,
                    navigation: {
                        nextEl: '.btn_nxt',
                        prevEl: '.btn_prv',
                    },
                    breakpoints: {
                        1360: {
                            slidesPerView: 3,
                            spaceBetween: 30,
                        },
                        1160: {
                            slidesPerView: 2,
                            spaceBetween: 30,
                        },
                    },
                });
            } else if ($("#productAssociationSwiper .swiper-slide.item").length === 2) {

                $('#productAssociationSwiper').css('max-width', '960px');

                var productAssociationSwiper2 = new Swiper('#productAssociationSwiper', {
                    slidesPerView: 2,
                    spaceBetween: 55,
                    allowTouchMove: false,
                    loop: false,
                    navigation: {
                        nextEl: '.btn_nxt',
                        prevEl: '.btn_prv',
                    }
                });
            }

            $('#tete_gondole').removeClass('small_swiper');
        }

        if ($("#productAssociationSwiper .swiper-slide.item").length === 4) {
            $('#productAssociationSwiper').addClass('no_swiper');
        }

    }, 500);

    window.setTimeout(function () {
        $('#productAssociationSwiper').css("opacity", "1");
    }, 1100);
});


window.setTimeout(function () {
    var swiper_home_main_push = $("#productAssociationSwiper .productBigPictures .wrap_video").width();
    $("#productAssociationSwiper .productBigPictures .wrap_video iframe").height(swiper_home_main_push * '1.3');
}, 1500);

/* Footer reassurance */

if (~window.location.href.indexOf("#commande")) {
    $('#theme_page').addClass('actif');
    $('.faq_theme_40').addClass('active');
    $('.faq_questions_40').show().removeClass('cache');
    $('#theme_global').removeClass('actif');

    $("html, body").delay(1000).animate({
        scrollTop: $('.faq_element.active').offset().top - 160
    }, 1500);
}

if (~window.location.href.indexOf("#livraison")) {
    $('#theme_page').addClass('actif');
    $('.faq_theme_42').addClass('active');
    $('.faq_questions_42').show().removeClass('cache');
    $('#theme_global').removeClass('actif');

    $("html, body").delay(1000).animate({
        scrollTop: $('.faq_element.active').offset().top - 160
    }, 1500);
}

$('.reassurance .secured_payment').on("click touch", function () {
    $('#theme_page').addClass('actif');
    $('.faq_element').removeClass('active');
    $('.faq_theme_40').addClass('active');
    $('.faq_questions').hide().addClass('cache');
    $('.faq_questions_40').show().removeClass('cache');
    $('#theme_global').removeClass('actif');
});

$('.reassurance .expedition_24, .reassurance .livraison_gratuite_magasin').on("click touch", function () {
    $('#theme_page').addClass('actif');
    $('.faq_element').removeClass('active');
    $('.faq_theme_42').addClass('active');
    $('.faq_questions').hide().addClass('cache');
    $('.faq_questions_42').show().removeClass('cache');
    $('#theme_global').removeClass('actif');
});

$(".reassurance a").click(function () {
    $("html, body").delay(1000).animate({
        scrollTop: $('.faq_element.active').offset().top - 160
    }, 1500);
});

/* ** */

(function () {
    var contactForm = $('#contact_form');
    var divEl = contactForm.find('fieldset.w-lines-group');
    var spanChamps = contactForm.find('.champs_requis');
    var submitBtn = contactForm.find('.w-submit .w-submit-button:not(.w-loader)');
    var msgField = contactForm.find('.w-textarea');

    /**
     * Deplacer le texte "Champs requis"
     * sous le champ message
     */
    if (divEl.length && spanChamps.length) {
        spanChamps.insertBefore(divEl);
    }

    /**
     * Verifier si la classe "w-has-error"
     * exist quand on clique sur le CTA "Envoyez"
     */
    submitBtn.on('click', function () {
        setTimeout(checkErrorClass, 300);
    });

    /**
     * Verifier si la classe "w-has-error"
     * exist quand on type dans le champ message
     */
    msgField.on('input', function () {
        checkErrorClass();
    });

    function checkErrorClass() {
        if (msgField.hasClass('w-has-error')) {
            msgField.closest('.w-form-line').addClass('w-has-error');
        } else {
            msgField.closest('.w-form-line').removeClass('w-has-error');
        }
    }

})();

/* INSTA */

$(".social-module .column_1").appendTo(".column_2 #sliderHomeInsta .swiper-slide:nth-child(3) div");

$(document).ready(function () {
    //Store locator
    var getBoxHeight = $('body.satellite_store_details #distribute_section_a').outerHeight();
    $('body.satellite_store_details #distribute_section_b #store_detail_map').css('height', getBoxHeight);

    //parrainage
    var getButton = $('body.parrainage_index .bloc_parrainage_form .form_btn');
    getButton.attr('id', 'add_friend')
    var moveBtn3 = getButton.find('.w-submit-button:nth-of-type(3)').attr('id', 'send_invite');
    var moveBtn2 = getButton.find('.w-submit-button:nth-of-type(2)').attr('id', 'send_invite');
    moveBtn3.appendTo($('body.parrainage_index .bloc_parrainage_form + .elem_parrainage'));
    moveBtn2.appendTo($('body.parrainage_index .bloc_parrainage_form + .elem_parrainage'));
    $('.w-submit-button#send_invite').css('opacity', '1');


});

/* Page inactive */
$("<span>*</span>").appendTo(".category .w-newsletter-form .w-email-input .w-input-label span");

/* Ajouter radio button pour le bloc virement bancaire */
$("<div class=\"radio\"></div>").appendTo("#bloc_virement");


/* Afficher le bloc virement bancaire en cliquant */
$("#bloc_virement p.bank_subtitle").on('click', function () {
    $("#bloc_virement .tunel_virement_detail").toggleClass('active');
    $("#wrapper_virement #bt_valider form").css('display', 'none');
});


/* Ajouter lionscroll sur la page livraison choix sur la liste des magasins */
window.setTimeout(function () {
    $("#choix_relais_relais .displayed_result .sidebar-right").overlayScrollbars({});
}, 200);


/* Retirer les semicolon dans chaque h3 classe item dans le pop up ecadeau étape 3 */
$(function () {
    var orderGiftCardFormStr = $("#orderGiftCardForm #step3 h3.item");
    var orderGiftCardFormStr2 = $("#orderGiftCardForm #step3 h3.item_2");
    $(orderGiftCardFormStr).each(function () {
        $(this).html($(this).html().replace(":", ""));
    })
    $(orderGiftCardFormStr2).each(function () {
        $(this).html($(this).html().replace(":", ""));
    })
});


/* Page E-cadeau */
if ($('.pageGiftCard').length) {
    $('.pageGiftCard__main--infos, .pageGiftCard__header .headerTitles').addClass('is-shown');
}

function toggleGiftCardBalance() {
    $(".pageGiftCard__header .headerTitles, .pageGiftCard__main, .pageGiftCard__main--amountinfos #showAmount").toggleClass('is-hidden is-shown');
}

/* Changer le positionnment des textes et boutons comme la maquette */
$('.giftCard_btn #openOrderGiftCard').insertAfter('.pageGiftCard__main .infosList');

/* Limiter nombre de charactère dans le champ message pop up ecadeau */
$('#s_msgPerso').attr('maxlength', '120');

var loc_edito = window.location.pathname;
var menus_edito = $(".wrapper_menu_satellite nav ul");
menus_edito.find("a").each(function () {
    $(this).toggleClass("active", $(this).attr("href") == loc_edito)
});

/**
 * Page - La marque histoire
 */
$(function () {

    if ($('body.satellite_histoire').length) {
        var histoirePage = $('body.satellite_histoire');
        var menuHistoire = histoirePage.find('#menu-lamarque-histoire');
        var stickyMenuPos = menuHistoire.offset().top - 150;
        var headerHeight = histoirePage.find('#site_head_wrap').height();
        var menuTop = histoirePage.find('#site_head_wrap #top_wrapper .header .menu_top');

        if (menuTop.css("position") === 'fixed') {
            headerHeight += (menuTop.height() - 1);
        }

        $(window).resize(function () {
            headerHeight = histoirePage.find('#site_head_wrap').height();

            if (menuTop.css("position") === 'fixed') {
                headerHeight += (menuTop.height() - 1);
            }
        });

        $(window).scroll(function () {
            headerHeight = histoirePage.find('#site_head_wrap').height();

            if (menuTop.css("position") === 'fixed') {
                headerHeight += (menuTop.height() - 1);
            }

            addStickyMenu(menuHistoire, stickyMenuPos, headerHeight);
        });

        getMenuLink(menuHistoire);

    }

    function getMenuLink(menuElm) {
        var link = menuElm.find('a');

        link.each(function () {
            $(this).on('click', function () {
                addElmClass($(this).parent(), 'active');
                scrollToElem($($(this).attr('href')));
            });
        });

    }

    function scrollToElem(elemId) {
        $('html, body').animate({
            scrollTop: elemId.offset().top - 200
        }, 1000);
    }

    function addElmClass(elem, elmClass) {
        if (!elem.hasClass(elmClass)) {
            elem.addClass(elmClass).siblings().removeClass(elmClass);
        }
    }

    function addStickyMenu(elm, stickyPos, topPos) {
        var scroll = $(window).scrollTop();

        if (scroll >= stickyPos) {
            elm.addClass('fixed');
            elm.css('top', topPos + 'px');
        } else {
            elm.removeClass('fixed');
            elm.css('top', '');
        }
    }

});


$("button.close_pop.close").on("click", function () {
    // if lightbox achat express does not exist, remove shade
    if (!($('#lightbox_achat_express').length)) {
        $("#shad").fadeTo("slow", 0, function () {
            $("#shad").css("display", "none");
        });
    }

    if ($('.page_rayon.rayon_lookbook').length) {
        $('body').css('position', '');
    }
});

/* SWIPER SELECTION-OFFER-CONTENT */
/* CHOIX COULEUR */
$(function () {
    if ($('#selection-offer-content .color_list.swiper-container .swiper-slide').length > 4) {
        new Swiper('#selection-offer-content .color_list.swiper-container', {
            slidesPerView: 5,
            spaceBetween: 10,
            navigation: {
                nextEl: '.nextColour',
                prevEl: '.prevColour',
            }
        });
    }
});

/* CHOIX SIZE */
$(function () {
    if ($('#selection-offer-content .size_list.swiper-container .swiper-slide').length > 4) {
        new Swiper('#selection-offer-content .size_list.swiper-container', {
            slidesPerView: 5,
            spaceBetween: 10,
            navigation: {
                nextEl: '.nextSize',
                prevEl: '.prevSize',
            }
        });
    }
});


/* Déplacer le push container dans le bloc left_side sur le tunnel panier */
$('body.step_1 .push_container').appendTo('body.step_1 .left_side');

$('<span>' + Translator.translate('produit_precommande_text_mdp') + '</span>').appendTo('body.step_1 .precoDeliveryDate');

$(document).ready(function () {
    /**
     * Formulaire contact
     * Rajouter "*" dans les champs
     */
    if ($('body.contact_faq #form_contact1').length) {
        var contactForm = $('body.contact_faq #form_contact1');
        var inputPrenom = contactForm.find('[name=contactPrenom]');
        var inputNom = contactForm.find('[name=contactNom]');
        var inputMail = contactForm.find('[name=contactMail]');
        let sService = $('.w-input-element[name="form_service"]');
        let fNumCmd = $('.w-contact-form #divContactNumCommande');

        sService.on('change', function () {
            if ($(this).val() == 1) {
                fNumCmd.show();
            } else {
                fNumCmd.hide();
            }
        });

        appendAsterisk(inputPrenom);
        appendAsterisk(inputNom);
        appendAsterisk(inputMail);
    }
});

function appendAsterisk(inputEl) {
    var label = inputEl.siblings('.w-input-label');
    return label.find('span').append('*');
}

$("#bloc_virement p.bank_subtitle").click(function () {
    $(this).toggleClass('hide');
});

$(document).ready(function video_responsive_r() {
    window.setTimeout(function () {
        $(window).on('load resize', function () {

            var video_max_height = $('.home-module.slider-module #productAssociationSwiper .productSwiper').height();
            $('.home-module.slider-module #productAssociationSwiper .wrap_video iframe').css('height', video_max_height);

        }).trigger('load resize');
    }, 2000);
});

$(function () {
    setTimeout(function () {
        $('#cookiesLightbox section h4').each(function () {
            var cookiesh4 = $(this);
            $(this).replaceWith('<p class="cookiesh4">' + cookiesh4.text() + '</p>');
        });

        $('.giftcard_index .pageGiftCard__header .headerTitles h2').each(function () {
            var giftcardh2 = $(this);
            $(this).replaceWith('<h1>' + giftcardh2.text() + '</h1>');
        });

        $('.giftcard_index .pageGiftCard__header .headerTitles h3').each(function () {
            var giftcardh3 = $(this);
            $(this).replaceWith('<h2 class="headerTitlesh2">' + giftcardh3.text() + '</h2>');
        });

        $('.giftcard_index .pageGiftCard__bloc .infosList h2').each(function () {
            var infosListHn = $(this);
            $(this).replaceWith('<p class="infosListHn">' + infosListHn.html() + '</p>');
        });

        $('.giftcard_index .pageGiftCard__bloc h1').each(function () {
            var pageGiftCardh1 = $(this);
            $(this).replaceWith('<p class="h1Style">' + pageGiftCardh1.html() + '</p>');
        });

        $('.giftcard_index .pageGiftCard__main h2').each(function () {
            var pageGiftCardmainH2 = $(this);
            $(this).replaceWith('<p class="h2Style">' + pageGiftCardmainH2.html() + '</p>');
        });

        $('#lightboxOrderGiftCard h2').each(function () {
            var lightboxOrderGiftCardH2 = $(this);
            $(this).replaceWith('<p class="h2Style">' + lightboxOrderGiftCardH2.html() + '</p>');
        });

        $('.giftDetails .js-textDeliveryMail  h2').each(function () {
            var lightboxOrderGiftCardH2 = $(this);
            $(this).replaceWith('<p class="h2Style">' + lightboxOrderGiftCardH2.html() + '</p>');
        });

        $('.giftcard_index .pageGiftCard__main h3').each(function () {
            var pageGiftCardmainH3 = $(this);
            $(this).replaceWith('<p class="h3Style">' + pageGiftCardmainH3.html() + '</p>');
        });

        $('.giftDetails .stepsList  h3').each(function () {
            var stepsListH3 = $(this);
            $(this).replaceWith('<p class="h3Style">' + stepsListH3.html() + '</p>');
        });

        $('#lightboxOrderGiftCard  h3').each(function () {
            var lightboxOrderGiftCardH3 = $(this);
            $(this).replaceWith('<p class="h3Style">' + lightboxOrderGiftCardH3.html() + '</p>');
        });

        $('#orderGiftCardForm  h4').each(function () {
            var orderGiftCardFormH4 = $(this);
            $(this).replaceWith('<p class="h4Style">' + orderGiftCardFormH4.html() + '</p>');
        });

        $('#footer_lafiancee  #listes h2').each(function () {
            var footer_lafianceeh2 = $(this);
            $(this).replaceWith('<span class="title">' + footer_lafianceeh2.html() + '</span>');
        });

        $(".signup-module .home_news_middle .w-input-element").attr("autocomplete", "off");

    }, 1000);

    $('.rayon_lookbook .in_lookbook h2:empty').remove();

    if ($('.cart_product_modify').length && $('.basketToWishlist').length) {
        $('.cart_product_desc').each(function () {
            if (!$(this).hasClass('reposition_qty')) {
                $(this).addClass('reposition_qty');
            }
        });
    }
});

$("#gondole_derniers_articles_vus").remove();

/**
 * Reveal animation
 * Page: Lookbook et Qui sommes-nous ?
 */
$(function () {
    var $animation_elements = $('.reveal-anim-elem');
    var $window = $(window);

    $window.on('scroll resize', check_if_in_view);
    $window.trigger('scroll');

    function check_if_in_view() {
        var window_height = $window.height();
        var window_top_position = $window.scrollTop();
        var window_bottom_position = (window_top_position + window_height);

        $.each($animation_elements, function () {
            var $element = $(this);
            var element_height = $element.outerHeight();
            var element_top_position = $element.offset().top;
            var element_bottom_position = (element_top_position + element_height);

            //check to see if this current container is within viewport
            if ((element_bottom_position >= window_top_position) &&
                (element_top_position <= window_bottom_position)) {
                $element.addClass('elem-revealed');
            }
        });
    }

    /* Ajout lionbar sur popup Selection Offerte */
    if ($('#selection-offer').length) {
        $('#selection-offer-content').overlayScrollbars({});
    }
});

setTimeout(function () {
    $('.cart_main_table.wishlist .cart_product_line .tunnel_wish_nostock').each(function () {
        $(this).parents('.cart_product_line').addClass('no_stock');
        $(this).show();
    });
}, 1000);

$(".cart .wishToBasket, .cart .remove_wish, .cart .product_del").click(function () {
    $('.cart_main_table.wishlist .cart_product_line .tunnel_wish_nostock').each(function () {
        $(this).parents('.cart_product_line').addClass('no_stock');
        $(this).show();
    });
});

/*Page Tunnel msg ereur pour code promo*/
var getTextError = $("body.cart #bloc_info_offert tr td #erreur_msg, body.checkout #bloc_info_offert tr td #erreur_msg").text();
$("body.cart #bloc_info_offert tr td #erreur_msg, body.checkout #bloc_info_offert tr td #erreur_msg").hide();
$("<span id='error_cart_cdpromo'>" + getTextError + "</span>").insertAfter($("body.cart #bloc_info_offert, body.checkout #bloc_info_offert"));

// ReloadBlocLogin
var reloadBlocLogin = function (evt) {

    var signIn = $('#auth');
    var passWd = $('#resetPass');
    var subLogin = $('#subtitleLogin');
    var subPassword = $('#subtitlePassword');

    evt.preventDefault();

    passWd.animate({
        opacity: 0
    }, 600, function () {

        passWd.css('display', 'none');

        signIn.css('display', '').animate({
            opacity: 1
        }, 600);
    });

    subPassword.animate({
        opacity: 0
    }, 600, function () {

        subPassword.css('display', 'none');
        subLogin.css('display', 'block').animate({
            opacity: 1
        }, 600);
    });
};

function newshowmodal() {
    topCartAppear();
    window.setTimeout(function () {
        topCartDesappear();
    }, 5000);
}
